<template>
  <section class="new-button-set-view">
    <div class="new-button-set-view__header px-4 pt-4 pb-3">
      <CiBreadcrumbs :breadcrumbs="breadcrumbs" class="mb-2" />
      <div style="display: flex; align-items: center;">
        <RdxButton
          rect
          :to="{ name: 'CarsInventoryButtonSets' }"
          class="ci-action--back"
        >
          <RdxIcon class="ri-arrow-left-line rdx-icon--24" />
        </RdxButton>
        <CiHeading level="1" class="ml-2">
          {{ $t("button-sets.new-set") }}
        </CiHeading>
        <div class="new-button-set-view__actions">
          <RdxButton
            outline
            class="mr-2"
            data-cy="cancel"
            :disabled="pending"
            @click="cancel"
          >
            {{ $t("global.cancel") }}
          </RdxButton>
          <RdxButton
            filled
            :disabled="!isValid || pending"
            @click="createButtonSet"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>
      </div>
    </div>

    <div class="new-button-set-view__inner">
      <div class="p-5">
        <div class="row new-button-set-view__grid">
          <div>
            <CiHeading level="2" class="mb-2">
              {{ $t("button-sets.settings") }}
            </CiHeading>
            <RdxInfo>
              {{ $t("button-sets.settings-description") }}
            </RdxInfo>
          </div>
          <div class="col-xl-9">
            <RdxInput
              v-model="button_set.name"
              :label="$t('button-sets.form.button-set-name-input-label')"
              :placeholder="
                $t('button-sets.form.button-set-name-input-placeholder')
              "
              class="mb-5"
            />
            <CiButtonSetWrapper>
              <template #header>
                <CiHeading level="2" class="mb-2">
                  {{ $t("button-sets.form.primary-button") }}
                </CiHeading>
              </template>
              <template #description>
                <RdxInfo>
                  {{ $t("button-sets.form.primary-button-description") }}
                </RdxInfo>
              </template>
            </CiButtonSetWrapper>
            <CiButtonSetEditForm
              v-if="primaryButton"
              :value="primaryButton"
              type="primary"
              :label-placeholder="
                $t('button-sets.form.label-input-placeholder-primary')
              "
              @input="updatePrimary"
            />
            <RdxDivider class="mb-5" />

            <CiButtonSetWrapper>
              <template #header>
                <CiHeading level="2" class="mb-2">
                  {{ $t("button-sets.form.secondary-button") }}
                </CiHeading>
              </template>
              <template #description>
                <RdxInfo>
                  {{ $t("button-sets.form.secondary-button-description") }}
                </RdxInfo>
              </template>
              <template #action>
                <RdxButton
                  v-if="!secondaryButtons.length"
                  outline
                  @click="addSecondary"
                >
                  {{ $t("button-sets.form.add-button") }}
                </RdxButton>
              </template>
            </CiButtonSetWrapper>
            <div v-if="secondaryButtons.length !== 0">
              <RdxGroupTabs
                :groups="secondaryButtons"
                :current="currentTabIndex"
                :tab-label="$t('button-sets.form.tab-label')"
                :tooltip-content="$t('button-sets.form.add-button-tooltip')"
                :max="4"
                @add="addSecondary"
                @current="changeTab"
                @remove="removeSecondary"
              />
              <CiButtonSetEditForm
                v-for="(secondaryButton, index) of secondaryButtons"
                v-show="currentTabIndex === index"
                :key="`button--${index}`"
                :value="secondaryButton"
                type="secondary"
                :label-placeholder="
                  $t('button-sets.form.label-input-placeholder-secondary')
                "
                @input="updateSecondary($event, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CiWarningModal
      v-if="warningModalOpen"
      :heading="$t('global.skip-editing-modal.header')"
      :paragraph="$t('global.skip-editing-modal.description')"
      @confirm="leavePage"
      @cancel="warningModalOpen = $event"
    >
      <template #confirm>
        <RdxButton filled class="ci-warning-modal__button" @click="leavePage">
          {{ $t("global.skip-editing-modal.confirm-button") }}
        </RdxButton>
      </template>
    </CiWarningModal>
  </section>
</template>

<script>
import {
  RdxButton,
  RdxIcon,
  RdxInput,
  RdxInfo,
  RdxDivider,
  RdxGroupTabs
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

import CiBreadcrumbs from "@/components/atoms/CiBreadcrumbs.vue";
import CiHeading from "@/components/atoms/CiHeading";
import CiButtonSetWrapper from "@/components/atoms/CiButtonSetWrapper";
import CiButtonSetEditForm from "@/components/molecules/CiButtonSetEditForm";
import CiWarningModal from "@/components/CiWarningModal/CiWarningModal";
import { isValid } from "./buttoSetValidation";

export default {
  name: "DuplicateButtonSet",

  components: {
    RdxButton,
    RdxIcon,
    RdxInput,
    RdxInfo,
    RdxDivider,
    RdxGroupTabs,

    CiHeading,
    CiBreadcrumbs,
    CiButtonSetWrapper,
    CiButtonSetEditForm,
    CiWarningModal
  },

  data() {
    return {
      currentTabIndex: 0,
      button_set_init: {},
      button_set: {
        buttons: []
      },
      secondaryInitState: {
        label: null,
        target_type: "url",
        button_type: "secondary",
        target: null,
        target_action: "new_tab"
      },

      warningModalOpen: false,
      pending: false
    };
  },

  computed: {
    breadcrumbs() {
      return [
        { text: "CarsInventory", link: { name: "CarsInventoryStock" } },
        {
          text: this.$t("navbar.buttons"),
          link: { name: "CarsInventoryButtonSets" }
        },
        { text: this.$t("button-sets.new-set") }
      ];
    },

    ID() {
      const { id } = this.$route.params;
      return id;
    },
    primaryButton() {
      return this.button_set.buttons.find(el => el.button_type === "primary");
    },
    secondaryButtons() {
      return this.button_set.buttons.filter(
        el => el.button_type === "secondary"
      );
    },

    isValid
  },

  watch: {
    secondaryButtons: {
      deep: true,
      handler(newList) {
        let listLength = newList.length;
        let maxIndex = listLength - 1;
        if (!listLength || this.currentTabIndex > maxIndex)
          this.currentTabIndex = 0;
      }
    }
  },

  mounted() {
    this.pending = true;
    this.$store
      .dispatch("buttonSet/fetchById", this.ID)
      .then(({ data: { data, included } }) => {
        const buttons = included.filter(({ type }) => type === "button");
        let init_data = {
          name: `${data.attributes.name} <${this.$t(
            "button-sets.form.button-set-name-copy-tag"
          )}>`,
          buttons: buttons.map(el => el.attributes)
        };
        this.button_set_init = this.cloneObject(init_data);
        this.button_set = this.cloneObject(init_data);
        this.pending = false;
      });
  },

  methods: {
    updatePrimary(value) {
      let index = this.button_set.buttons.indexOf(this.primaryButton);
      this.$set(this.button_set.buttons, index, value);
    },
    updateSecondary(value, index) {
      this.$set(this.button_set.buttons, index + 1, value);
    },
    addSecondary() {
      this.button_set.buttons.push(this.secondaryInitState);
      this.currentTabIndex = this.secondaryButtons.length - 1;
    },
    changeTab(index) {
      this.currentTabIndex = index;
    },
    removeSecondary({ index }) {
      this.button_set.buttons.splice(index + 1, 1);
    },

    async createButtonSet() {
      this.pending = true;
      try {
        await this.$store.dispatch("buttonSet/create", {
          data: {
            attributes: this.button_set
          }
        });

        this.$toast.info(this.$t("button-sets.button-set-created-successfull"));
        this.$router.push({
          name: "CarsInventoryButtonSets"
        });
      } finally {
        this.pending = false;
      }
    },

    converToString(object) {
      return JSON.stringify(object);
    },
    cloneObject(object) {
      return JSON.parse(this.converToString(object));
    },

    cancel() {
      this.warningModalOpen = true;
    },
    leavePage() {
      this.$router.push({
        name: "CarsInventoryButtonSets"
      });
    }
  }
};
</script>

<style lang="scss">
.new-button-set-view {
  &__header {
    border-bottom: 1px solid $grey-5;
    padding: 17px 24px 21px;
  }

  &__inner {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 186px);
    overflow: auto;
  }

  &__actions {
    margin-left: auto;
  }

  &__grid {
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .rdx-group-tab {
    background-color: #fff;

    &--current {
      background-color: #fff;
      &:after {
        border-color: #fff;
      }
    }
  }
}
</style>
